import { AxiosResponse } from 'axios';
import { post } from '../Request/Request';
import { APIResponse } from '../Helper/type';
import IUserLogin from './UserLogin.interface';
import { handleApiResponseLogin } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class UserLogin {
  public apiResponse: APIResponse | undefined;

  // Declaring the constructor to get the request body. This constructor is of type IUser
  constructor(public loginInfo: IUserLogin) {}

  // Declaring the sub-path
  // public loginPath: string = '/login';
  public loginPath: string = '/login';
  public authenticateUserPath: string = '/authenticateUser';
  public resendUrlPath: string = '/resendurl';
  public mfaSetupVerificationPath: string = '/mfaSetupVerification';

  // POST request for Login and storing it to db. This methods returns a response body along with a status code.
  public async doLogin(otp: string): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.loginPath, 'POST');
      const url: string = this.createUri(this.loginPath);
      const requestBody: any = this.loginInfo;
      requestBody.otp = otp;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      // Flag to determine whether the response is for different API or not
      const isUserLoggedIn = false;
      // storing jwt token and role so that we can use it for further api calls.
      const jwtToken = response.data.cognitoDetails.message.idToken.jwtToken;
      const preferredRoleArn =
        response.data.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const preferredRole = preferredRoleArn.split('/').pop();
      const groupName = response.data.cognitoDetails.message.idToken.payload['cognito:groups'][0];
      // storing groupname to show on the user page.
      sessionStorage.setItem('groupName', groupName);
      sessionStorage.setItem('jwtToken', jwtToken);
      sessionStorage.setItem('preferredRole', preferredRole);
      this.apiResponse = handleApiResponseLogin(response, isUserLoggedIn);
    } catch (error: any) {
      switch (error?.response?.status) {
        case 301:
          this.apiResponse = { statusCode: 301, body: JSON.stringify(error) };
          break;
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'User is not verified'
          };
          break;
        case 423:
          this.apiResponse = {
            statusCode: 423,
            body: 'Account is locked, please contact BT Admin'
          };
          break;
        case 429:
          this.apiResponse = {
            statusCode: 429,
            body: 'Account is temporarily Locked, Please try after 10 mins'
          };
          break;
        case 401:
          this.apiResponse = {
            statusCode: 401,
            body: 'invalid credentials.'
          };
          break;
        case 410:
          this.apiResponse = {
            statusCode: 410,
            body: 'OTP mismatched.'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  public async userMfaSetup(otp: string, accessToken: string): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.mfaSetupVerificationPath, 'POST');
      const url: string = this.createUri(this.mfaSetupVerificationPath);
      const requestBody: any = this.loginInfo;
      requestBody.otp = otp;
      requestBody.accessToken = accessToken;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      // Flag to determine whether the
      this.apiResponse = {
        statusCode: 201,
        body: 'MFA authentication successful.'
      };
    } catch (error: any) {
      switch (error?.response?.status) {
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'Invalid User ID or Password'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  // POST request for Resending URL while user is not confirmed for Admin and storing it to db. This methods returns a response body along with a status code.
  public async resendUrl(): Promise<APIResponse> {
    try {
      const axiosWithRoles = apiCallWrapper(this.resendUrlPath, 'POST');
      const url: string = this.createUri(this.resendUrlPath);
      const requestBody: any = this.loginInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      // Flag to determine whether the response is for different API or not
      const isUserLoggedIn = true;
      this.apiResponse = handleApiResponseLogin(response, isUserLoggedIn);
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  private createUri(paramsResendUrl?: string): string {
    return backEndServerUrl + paramsResendUrl;
  }
}
