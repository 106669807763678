import { AxiosResponse } from 'axios';
import { get } from '../Request/Request';
import IModuleList from '../ModuleList/ModuleList.interface';
import { ModuleList } from './Module.helper';
import IModule from './Module.interface';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class OrganizationAdmin {
  // Declaring the sub-path
  public subPath: string = '/organization/module';

  // GET request for fetching data from db to Module list. This method is an array of type IModuleList or null
  public async getAllModule(): Promise<Array<IModuleList | null>> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'GET');
      const url: string = this.createUrl(this.subPath);
      const response: AxiosResponse<any, any> = await axiosWithRoles.get(url);
      const arrAdmin: Array<IModule | null> = [];
      response.data.forEach((element: IModule) => {
        arrAdmin.push(element);
      });
      return ModuleList(arrAdmin);
    } catch (error: any) {
      alert(error);
    }
    return await this.getAllModule();
  }

  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
