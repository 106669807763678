import { AxiosResponse } from 'axios';
import { put } from '../Request/Request';
import { APIResponse } from '../Helper/type';
import IOrgUser from './OrgUser.interface';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class UpdateOrganization {
  public apiResponse: APIResponse | undefined;

  // Declaring the constructor to get the request body. This constructor is of type IUpdateOrgAdmin
  constructor(public userInfo: IOrgUser) {}
  // Declaring the sub-path
  public subPath: string = '/organization/admin';

  // POST request for updating Organization Users and storing it to db. This methods returns a response body along with a status code.
  public async updateOrgAdmin(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'PUT');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.put(url, requestBody);
      const errorMessage = '';
      const successMessage = '';
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
