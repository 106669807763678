import axios, { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import IOrgUser from '../OrgUser/OrgUser.interface';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path to call api
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
/* this class is used to call send otp api, when user click on send code button it will
send verification code to user email to reset the password */
export default class SendVerificationCode {
  public apiResponse: APIResponse | undefined;
  constructor(public userInfo: IOrgUser) {}
  // Declaring the sub-path
  public subPath: string = '/reset/otp';

  // Post request to send the code in useremail.
  public async sendCode(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'POST');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      const errorMessage = '';
      const successMessage = '';
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      switch (error?.response?.status) {
        case 404:
          this.apiResponse = {
            statusCode: 404,
            body: 'User not found.'
          };
          break;
        case 400:
          this.apiResponse = {
            statusCode: 400,
            body: 'User not verified, please verify first.'
          };
          break;
        case 429:
          this.apiResponse = {
            statusCode: 429,
            body: 'Too many requests,please try again later.'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path
  private createUrl(params?: string): string {
    return `${backEndServerUrl}${params}`;
  }
}
