import React from 'react';
import InputField from '../../library/inputField/inputField';
import Label from '../../library/label/label';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import domainRegex from '../../utils/domainRegex';

interface PartnerOrganisationDomainsProps {
  domains: string[];
  onOrganisationDomainChange: (domain: string[]) => void;
}

const PartnerOrganisationDomains: React.FC<PartnerOrganisationDomainsProps> = ({
  domains,
  onOrganisationDomainChange
}) => {
  const onDomainChange = (_domains: string[]) => {
    onOrganisationDomainChange([..._domains]);
  };
  return (
    <div className="block ">
      <div className="p-0 w-full float-left relative">
        <div className="w-full my-5 mx-0 relative table border-separate ">
          <Label
            className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
            LabelText="Partner company domain:"
          />

          <div className="px-0 w-[31.25%] table">
            <ReactMultiEmail
              placeholder="Input your domain"
              emails={domains}
              onChange={onDomainChange}
              autoFocus={true}
              style={{ minWidth: '100px', maxWidth: '362px' }}
              validateEmail={(email) => {
                if (email === 'undefined') {
                  return false;
                }
                const isValid = domainRegex.test(email);
                return isValid;
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}></span>
                  </div>
                );
              }}
            />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PartnerOrganisationDomains;
