import { createContext, useContext } from 'react';

export interface LoginContextType {
  loginToken: string;
  updateToken: (value: string) => void;
}

export const LoginContext = createContext<LoginContextType>({
  loginToken: '',
  updateToken: function (value: string): void {
    throw new Error('Function not implemented.');
  }
});
