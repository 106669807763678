import React, { FormEvent, ReactNode, useEffect, useState } from 'react';
import { Dropdown } from '../../components/Dropdown';
import Button from '../Button/button';
import CustomPopup from '../../components/BtAdminPanel/CustomPopup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputField from '../inputField/inputField';
import { IPPFourthLevelHeading } from '../Heading/Heading';
import Label from '../label/label';
import ValidatedInputField from '../ValidatedInputField/ValidatedInputField';

/**
 * This common props takes in several parameters.
 * @setName ,@setEmail ,@setTitle ,@setFname ,@setLname ,@setDescription ,@setCompanyHeadQuarterAddress ,@setJobTitle ,@setPhone ,@setRole ,@setUsersEmail - These props are likely used to update the corresponding form input fields when their values change. They are functions that take a string or any value and update the state of the respective input fields.
 * @register - This prop is a function that is likely used for registering form input fields with a form library or framework. It takes an input name as a string and an object specifying whether the field is required or not. It returns attributes and event handlers that need to be applied to the input element.
 * @createUser - This prop is a function that is likely called when the form is submitted. It takes an event (e) and handles the logic to create a user based on the form inputs.
 * @setShowForm ,@setShowFormEdit - These props are used to control the visibility of the form component. They likely update the state to show or hide the form based on certain conditions.
 * @phone ,@jobTitle ,@companyHeadquarterAddress ,@description ,@lname ,@fname ,@email ,@title ,@optionTitle ,@partnerCompanyName ,@orgEmail ,@role ,@optionJobTitle ,@orgName ,@usersEmail - These props likely represent the values of the form inputs. They can be used to initialize the input fields or to retrieve the user-entered values.
 * @onSubmit - This prop is a function that is likely called when the form is submitted. It takes an event (e) and handles the form submission logic.
 * @show - This prop represents the visibility of the form component.
 * @message - This prop represents a message to be displayed in the component, possibly related to form submission or errors.
 * @showLoader - This prop represents whether a loader or spinner should be displayed to indicate a loading state.
 * @errors - This prop likely represents any form validation errors that occurred during submission.
 **/
interface FormProps {
  domain?: string | any;
  setDomain?: string | any;
  optionDomain?: string[] | any;
  setName?: string | any;
  setEmail?: string | any;
  setTitle?: string | any;
  setFname?: string | any;
  setLname?: string | any;
  register?: any;
  setDescription?: string | any;
  setCompanyHeadQuarterAddress?: string | any;
  setJobTitle?: string | any;
  setPhone?: string | any;
  setRole?: string | any;
  createUser: (e: FormEvent<Element>) => void;
  submitFeedback?: (e: FormEvent<Element>) => void;
  setShowForm?: boolean | any;
  setShowFormEdit?: boolean | any;
  orgPhone?: string | number | readonly string[] | undefined;
  orgJobTitle?: string | any;
  orgCompanyHaedquaterAddress?: string | any;
  orgDescription?: string | any;
  userLname?: string | any;
  userFname?: string | any;
  orgEmail?: string | any;
  onSubmit: (e: FormEvent<Element>) => void;
  userTitle?: string | any;
  optionTitle?: string | any;
  orgPartnerCompanyName?: string | any;
  organisationEmail?: string | any;
  show?: boolean;
  showEditForm?: boolean;
  message?: ReactNode;
  showLoader?: boolean | undefined;
  role?: string | any;
  optionJobTitle?: string | any;
  errors?: string | any;
  organisationName?: string | any;
  showFormEdit?: any;
  isBtAdmin?: boolean;
  usersEmail?: string;
  setUsersEmail?: string | any;
  isFeedback?: boolean;
  feedbackMessage?: string | any;
  setFeedbackMessage?: string | any;
  userName?: string | any;
  setUserName?: string | any;
  phone?: string | any;
  reset?: any;
}

/* The form can be used to create a new user or edit a partner organization,depending on the value of the showFormEdit prop.
If showFormEdit is false, the form will be used to create a new user, otherwise, it will be used to edit a partner organization. */
const Form: React.FC<FormProps> = (props) => {
  // Handler function for closing the edit partner organisation response message pop-up
  const popupCloseAlertHandlerEdit = () => {
    // Set the showFormEdit state to the value of e
    props.setShowFormEdit(false);
  };

  // Handler function for closing the create admin user response message pop-up
  const popupCloseAlertHandler = () => {
    props.setShowForm(false);
    props.reset();
  };

  const [valid, setValid] = useState(false);
  const [validDomain, setValidDomain] = useState(true);
  const [validUpdate, setValidUpdate] = useState(true);
  const handleOn_change = (event: any): any => {
    props.setPhone(event);
    setValid(validatePhoneNumber(event));
    setValidUpdate(validatePhoneNumber(event));
  };
  const validatePhoneNumber = (phoneNumber: any) => {
    const phoneNumberPattern = /^\d{8,15}$/; // Validates a 8 to 15 digit number

    return phoneNumberPattern.test(phoneNumber);
  };

  const companyName = props.organisationName;
  const companyNameEdit = props.orgPartnerCompanyName;

  const createAdminFormValidation = props.showLoader === true || !valid;

  const updateAdminFormValidation =
    props.errors.fullname !== undefined ||
    props.errors.companyHaedquaterAddress !== undefined ||
    props.errors.description !== undefined ||
    props.orgJobTitle === '';

  const checkEmailDomain = (email: string) => {
    if (props.optionDomain) {
      if (props.optionDomain.length > 0) {
        setValidDomain(props.optionDomain.some((domain: string) => email?.includes(domain)));
      }
    }
  };

  //  Render the component
  return (
    <div>
      {/* This code defines a form for creating a new user in a BT Admin application. The form is
      conditionally rendered based on the value of the `showFormEdit` prop. If the prop is true,
      the form will be shown, otherwise it will be hidden. */}
      {!props.isFeedback ? (
        <>
          {!props.showFormEdit ? (
            // If `showFormEdit` is false, render the form.
            <div>
              {!props.isBtAdmin ? (
                <>
                  <div className="flex start justify-between px-4 py-4 border-b border-black">
                    <IPPFourthLevelHeading
                      headerText={'BT Admin | Create User Form'}
                      className={'mb-0 leading-normal text-2xl text-black'}
                    />
                  </div>
                  {/* This div contains the actual form elements. */}
                  <div className="position-relative grow shrink basis-auto px-4 py-4 block">
                    {' '}
                    {/* The form is defined here. */}
                    <form className="text-left" id="myForm" onSubmit={props.createUser}>
                      <ValidatedInputField
                        label="Creating User for"
                        id="OrganisationName"
                        type="text"
                        placeholder="This is usually your Partner Name"
                        style={{ border: '1px solid #444' }}
                        value={props.organisationName}
                        disabled
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        }
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label="Partner Admin's User Name"
                        id="OrganisationEmail"
                        type="text"
                        placeholder="This is usually your Admin's Email Address"
                        style={{ border: '1px solid #444' }}
                        value={props.organisationEmail}
                        disabled
                        className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label="Name"
                        id="UserName"
                        type="text"
                        placeholder="This is usually your User's Name"
                        register={props.register?.('name', {
                          required: true,
                          pattern: {
                            value: /^([^0-9]*)$/,
                            message: 'Numbers not allowed'
                          }
                        })}
                        errors={props.errors.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          props.setName(e.target.value);
                        }}
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        }
                        requiredField={'required-field'}
                      />
                      <ValidatedInputField
                        label={'Email'}
                        id="UserEmail"
                        type={'email'}
                        validEmailDomain={validDomain}
                        placeholder="This is usually your User's Email Address"
                        register={props.register?.('email', {
                          required: 'Email is required',
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            checkEmailDomain(e.target.value);
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Invalid email address'
                          }
                        })}
                        style={
                          props.errors.email == null && validDomain
                            ? { border: '1px solid #666' }
                            : { border: '1px solid #e60014' }
                        }
                        errors={props.errors.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          props.setEmail(e.target.value);
                        }}
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        }
                        requiredField={'required-field'}
                      />

                      <Label
                        className="inline mb-0 mt-2 pr-[56rem] required-field"
                        LabelText="Portal role"
                      />
                      <br />
                      <Dropdown
                        options={props.optionJobTitle}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          props.setRole(e.target.value)
                        }
                        selectedValue={props.role}
                      />
                      <Label className="inline mb-0 mt-2 pr-[56rem]" LabelText="Mobile phone" />
                      <div className="px-0 w-[31.25%] table">
                        <PhoneInput
                          country={'gb'}
                          placeholder="Enter your mobile no"
                          value={props.phone}
                          onChange={handleOn_change}
                          inputProps={{
                            required: true
                          }}
                          inputStyle={{
                            width: '70rem',
                            height: '30px',
                            border: '1px solid rgb(102, 102, 102)',
                            borderRadius: '0.5rem'
                          }}
                          buttonStyle={{ border: '1px solid rgb(102, 102, 102)' }}
                        />
                      </div>
                      <br />
                      <Button
                        className={
                          props.role === '' || !validDomain
                            ? 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem] cursor-not-allowed bg-trans disabled:opacity-20'
                            : 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem]'
                        }
                        buttonText="Submit"
                        onclick={props.createUser}
                        loading={props.showLoader}
                        disabled={props.role === '' || !validDomain}
                      />
                      {/* Replace the custom alert box with a pop-up screen. */}
                      <CustomPopup onClose={popupCloseAlertHandler} show={props.show}>
                        <div className="max-h-30% ">
                          <p className="mr-0 mb-0.5 ml-0 text-[17px]">{props.message}</p>
                        </div>
                      </CustomPopup>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex start justify-between px-4 py-4 border-b border-black">
                    <IPPFourthLevelHeading
                      headerText={`${companyName} Admin | Create User Form`}
                      className={'mb-0 leading-normal text-2xl text-black'}
                    />
                  </div>
                  <div className="position-relative grow shrink basis-auto px-4 py-4 block">
                    {' '}
                    {/* The form is defined here. */}
                    <form className="text-left" id="myForm" onSubmit={props.createUser}>
                      <ValidatedInputField
                        label="Creating User for"
                        id="OrganisationNamePartnerAdmin"
                        type="text"
                        placeholder="This is usually your Partner Name"
                        style={{ border: '1px solid #444' }}
                        value={props.organisationName}
                        disabled
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        }
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label="Partner Admin's User Name"
                        id="OrganisationEmailPartnerAdmi"
                        type="text"
                        placeholder="This is usually your Admin's Email Address"
                        style={{ border: '1px solid #444' }}
                        value={props.organisationEmail}
                        disabled
                        className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label="Name"
                        id="UserNamePartnerAdmin"
                        type="text"
                        placeholder="This is usually your Admin User's Name"
                        register={props.register?.('name', {
                          required: true,
                          pattern: {
                            value: /^([^0-9]*)$/,
                            message: 'Numbers not allowed'
                          }
                        })}
                        errors={props.errors.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          props.setName(e.target.value)
                        }
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        }
                        requiredField={'required-field'}
                      />
                      <ValidatedInputField
                        label="Email"
                        id="UserEmailPartnerAdmin"
                        type={!validDomain ? 'Please use proper your email domain' : 'email'}
                        validEmailDomain={validDomain}
                        placeholder="This is usually your Admin User's Email Address"
                        register={props.register('email', {
                          required: 'Email is required',
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            checkEmailDomain(e.target.value);
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Invalid email address'
                          }
                        })}
                        style={
                          props.errors.email == null && validDomain
                            ? { border: '1px solid #666' }
                            : { border: '1px solid #e60014' }
                        }
                        errors={props.errors.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          props.setEmail(e.target.value);
                        }}
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        }
                        requiredField={'required-field'}
                      />
                      <Label
                        className="inline mb-0 mt-2 pr-[56rem] required-field"
                        LabelText="Portal role"
                      />
                      <br />
                      <Dropdown
                        options={props.optionJobTitle}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          props.setRole(e.target.value)
                        }
                        selectedValue={props.role}
                      />
                      <Label className="inline mb-0 mt-2 pr-[56rem]" LabelText="Mobile phone" />
                      <div className="px-0 w-[31.25%] table">
                        <PhoneInput
                          country={'gb'}
                          placeholder="Enter your mobile no"
                          value={props.phone}
                          onChange={handleOn_change}
                          inputProps={{
                            required: true
                          }}
                          inputStyle={{
                            width: '70rem',
                            height: '30px',
                            border: '1px solid rgb(102, 102, 102)',
                            borderRadius: '0.5rem'
                          }}
                          buttonStyle={{ border: '1px solid rgb(102, 102, 102)' }}
                        />
                      </div>
                      <br />
                      <Button
                        className={
                          props.role === '' || !validDomain
                            ? 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem] cursor-not-allowed bg-trans disabled:opacity-20'
                            : 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem]'
                        }
                        buttonText="Submit"
                        onclick={props.createUser}
                        loading={props.showLoader}
                        disabled={props.role === '' || !validDomain}
                      />
                      {/* Replace the custom alert box with a pop-up screen. */}
                      <CustomPopup onClose={popupCloseAlertHandler} show={props.show}>
                        <div className="max-h-30% ">
                          <p className="mr-0 mb-0.5 ml-0 text-[17px]">{props.message}</p>
                        </div>
                      </CustomPopup>
                    </form>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div>
              {!props.isBtAdmin ? (
                <>
                  <div className="flex start justify-between px-1 py-1 border-b border-black">
                    <IPPFourthLevelHeading
                      headerText={'BT Admin | Edit User Form'}
                      className={'mb-0 leading-normal text-xl text-black'}
                    />
                  </div>
                  <div className="position-relative grow shrink basis-auto px-1 py-1 block">
                    {' '}
                    <form className="text-left" id="myForm" onSubmit={props.onSubmit}>
                      <ValidatedInputField
                        label="Partner Admin's User Name"
                        id="OrgEmail"
                        type="text"
                        placeholder="This is usually your Partner Name"
                        style={{ border: '1px solid #444' }}
                        value={props.orgEmail}
                        disabled
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        }
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label="Partner Company Name"
                        id="OrgPartnerCompanyName"
                        type="text"
                        placeholder="This is usually your Partner Name"
                        style={{ border: '1px solid #444' }}
                        value={props.orgPartnerCompanyName}
                        disabled
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        }
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label={'Name'}
                        id={'FullName'}
                        type={'text'}
                        placeholder={'This is usually your Name'}
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        }
                        maxLength={60}
                        errors={props.errors.fullname}
                        value={props.userFname}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          props.setFname(event.target.value)
                        }
                        requiredField={'required-field'}
                      />
                      <Label
                        className="inline mb-0 mt-2 pr-[62rem] required-field"
                        LabelText="Portal role"
                      />
                      <br />
                      <Dropdown
                        options={props.optionJobTitle}
                        handleChange={(e: any) => props.setJobTitle(e.target.value)}
                        selectedValue={props.orgJobTitle}
                      />
                      <Label className="inline mb-0 mt-2">
                        {props.errors.phone == null ? (
                          <span className="pr-[61rem]">Mobile Phone</span>
                        ) : (
                          <span className="pr-[45rem]">{props.errors.phone}</span>
                        )}
                      </Label>
                      <br />
                      <PhoneInput
                        country={'gb'}
                        placeholder="Enter your mobile no"
                        value={String(props.orgPhone ?? '')}
                        onChange={handleOn_change}
                        inputProps={{
                          required: true
                        }}
                        inputStyle={{
                          width: '80rem',
                          height: '30px',
                          border: '1px solid rgb(102, 102, 102)',
                          borderRadius: '0.5rem'
                        }}
                        buttonStyle={{ border: '1px solid rgb(102, 102, 102)' }}
                      />
                      <ValidatedInputField
                        label={'Company headquarter address'}
                        id={'companyHeadQuarterAddress'}
                        type={'text'}
                        placeholder={'This is usually your headquarter address'}
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        }
                        errors={props.errors.companyHaedquaterAddress}
                        value={props.orgCompanyHaedquaterAddress}
                        onChange={(event) =>
                          props.setCompanyHeadQuarterAddress(
                            (event.target as HTMLInputElement).value
                          )
                        }
                        requiredField={'required-field'}
                      />
                      <ValidatedInputField
                        label={'Description'}
                        id={'orgDescription'}
                        type={'text'}
                        placeholder={"This is usually your company's description"}
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        }
                        errors={props.errors.description}
                        value={props.orgDescription}
                        onChange={(event) =>
                          props.setDescription((event.target as HTMLInputElement).value)
                        }
                        requiredField={'required-field'}
                      />
                      <br />
                      <Button
                        className={
                          updateAdminFormValidation
                            ? 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem] cursor-not-allowed bg-trans disabled:opacity-20'
                            : 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem]'
                        }
                        buttonText="Update"
                        onclick={props.onSubmit}
                        loading={props.showLoader}
                        disabled={updateAdminFormValidation}
                      />
                      {/* Replace the custom alert box with a pop-up screen. */}
                      <CustomPopup onClose={popupCloseAlertHandlerEdit} show={props.showEditForm}>
                        <div className="max-h-30% ">
                          <p className="mr-0 mb-0.5 ml-0 text-[17px]">{props.message}</p>
                        </div>
                      </CustomPopup>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex start justify-between px-4 py-4 border-b border-black">
                    <IPPFourthLevelHeading
                      headerText={`${companyNameEdit} Admin | Edit User Form`}
                      className={'mb-0 leading-normal text-2xl text-black'}
                    />
                  </div>
                  <div className="position-relative grow shrink basis-auto px-4 py-4 block">
                    {' '}
                    <form className="text-left" id="myForm" onSubmit={props.onSubmit}>
                      <ValidatedInputField
                        label="Partner Admin's User Name"
                        id="orgEmail"
                        type="text"
                        placeholder="This is usually your Partner Name"
                        style={{ border: '1px solid #444' }}
                        value={props.orgEmail}
                        disabled
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        }
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label="Partner Company Name"
                        id="orgPartnerCompanyName"
                        type="text"
                        placeholder="This is usually your Partner Name"
                        style={{ border: '1px solid #444' }}
                        value={props.orgPartnerCompanyName}
                        disabled
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        }
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label="Email"
                        id="usersEmail"
                        type="text"
                        placeholder="This is usually your Partner Name"
                        style={{ border: '1px solid #444' }}
                        value={props.usersEmail}
                        disabled
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] cursor-not-allowed bg-trans disabled:opacity-20"
                        }
                        requiredField={''}
                      />
                      <ValidatedInputField
                        label={'Name'}
                        id={'UserFullName'}
                        type={'text'}
                        maxLength={60}
                        placeholder={'This is usually your Name'}
                        className={
                          "h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                        }
                        errors={props.errors.name}
                        value={props.userFname}
                        onChange={(event) => props.setFname(event.target.value)}
                        requiredField={'required-field'}
                      />
                      <Label className="inline mb-0 mt-2 pr-[62rem]" LabelText="Portal role" />
                      <br />
                      <Dropdown
                        options={props.optionJobTitle}
                        handleChange={(e: any) => props.setJobTitle(e.target.value)}
                        selectedValue={props.orgJobTitle}
                      />
                      <Label className="inline mb-0 mt-2">
                        {props.errors.phone == null ? (
                          <span className="pr-[61rem]">Mobile Phone</span>
                        ) : (
                          <span className="pr-[45rem]">{props.errors.phone}</span>
                        )}
                      </Label>
                      <PhoneInput
                        country={'gb'}
                        placeholder="Enter your mobile no"
                        value={String(props.phone)}
                        onChange={handleOn_change}
                        inputProps={{
                          required: true
                        }}
                        inputStyle={{
                          width: '80rem',
                          height: '30px',
                          border: '1px solid rgb(102, 102, 102)',
                          borderRadius: '0.5rem'
                        }}
                        buttonStyle={{ border: '1px solid rgb(102, 102, 102)' }}
                      />
                      <br />
                      <Button
                        className={
                          props.errors.name !== undefined || props.orgJobTitle === ''
                            ? 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem] cursor-not-allowed bg-trans disabled:opacity-20'
                            : 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem]'
                        }
                        buttonText="Update"
                        onclick={props.onSubmit}
                        loading={props.showLoader}
                        disabled={props.errors.name !== undefined || props.orgJobTitle === ''}
                      />
                      {/* Replace the custom alert box with a pop-up screen. */}
                      <CustomPopup onClose={popupCloseAlertHandlerEdit} show={props.showEditForm}>
                        <div className="max-h-30% ">
                          <p className="mr-0 mb-0.5 ml-0 text-[17px]">{props.message}</p>
                        </div>
                      </CustomPopup>
                    </form>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex start justify-between px-4 py-4 border-b border-black">
            <IPPFourthLevelHeading
              headerText={'Feedback / Query Form'}
              className={'mb-0 leading-normal text-2xl text-black'}
            />
          </div>
          <div className="position-relative grow shrink basis-auto px-4 py-4 block">
            {' '}
            <form className="text-left" id="myForm" onSubmit={props.onSubmit}>
              <Label className="inline mb-0 mt-2">
                {props.errors.feedbackMessage == null ? (
                  <span className="pr-[62rem]">Feedback / Query</span>
                ) : (
                  <span className="pr-[49rem]">{props.errors.feedbackMessage}</span>
                )}
              </Label>
              <br />
              <textarea
                id="message"
                rows={4}
                cols={50}
                className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Write your thoughts here..."
                style={
                  props.errors.name == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                value={props.feedbackMessage}
                onChange={(event) => props.setFeedbackMessage(event.target.value)}
              />
              <br />
              <Button
                className={
                  props.errors.feedbackMessage !== undefined
                    ? 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem] cursor-not-allowed bg-trans disabled:opacity-20'
                    : 'inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[0rem]'
                }
                buttonText="Submit"
                onclick={props.onSubmit}
                loading={props.showLoader}
                disabled={props.errors.feedbackMessage !== undefined}
              />
              {/* Replace the custom alert box with a pop-up screen. */}
              <CustomPopup onClose={popupCloseAlertHandler} show={props.show}>
                <div className="max-h-30% ">
                  <p className="mr-0 mb-0.5 ml-0 text-[17px]">{props.message}</p>
                </div>
              </CustomPopup>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
export default Form;
