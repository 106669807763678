import React, { useState } from 'react';
import Button from '../../library/Button/button';
import InputField from '../../library/inputField/inputField';

interface PartnerDomainRowProps {
  domain: string;
  key: number;
  isSelected: boolean;
  onDomainChecked: (isSelected: boolean) => void;
}

const PartnerDomainRow: React.FC<PartnerDomainRowProps> = ({
  domain,
  key,
  isSelected,
  onDomainChecked
}) => {
  return (
    <div className="flex flex-row justify-start my-2.5" key={key}>
      <InputField
        type="checkbox"
        className="text-blue-600 mr-2.5"
        value="I accept the terms and conditions."
        checked={isSelected}
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
          onDomainChecked(!isSelected);
        }}
        id={'moduleName'}
      />
      <p>{domain}</p>
    </div>
  );
};

export default PartnerDomainRow;
