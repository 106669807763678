import axios, { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import IOrgUser from './OrgUser.interface';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path to call api
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
/* this class is used to call mfaReset api, when user click on reset button it will
call this mfaReset function and send the request to backend via axios. In request body we are sending user email */
export default class ReseUsertMfa {
  public apiResponse: APIResponse | undefined;
  // public userEmail = sessionStorage.getItem('Email');

  constructor(public userEmail: string) {}
  // Declaring the sub-path
  public subPath: string = '/mfaReset';

  // POST request to reset an organization
  public async resetMfaUser(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'POST');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userEmail;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, {
        email: requestBody
      });
      const errorMessage = '';
      const successMessage = '';
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path
  private createUrl(params?: string): string {
    return `${backEndServerUrl}${params}`;
  }
}
