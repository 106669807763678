import React from 'react';

// Define your custom password rules
export const PasswordRules = (password: string) => {
  const rules = [
    {
      id: 'length',
      description: '16-63 characters required',
      isValid: password.length >= 16 && password.length <= 63
    },
    {
      id: 'uppercase',
      description: 'Include at least one uppercase letter',
      isValid: /[A-Z]/.test(password)
    },
    {
      id: 'lowercase',
      description: 'Include at least one lowercase letter',
      isValid: /[a-z]/.test(password)
    },
    {
      id: 'number',
      description: 'Include at least one number',
      isValid: /\d/.test(password)
    },
    {
      id: 'specialChar',
      description: 'Include ≥1 special character',
      isValid: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    },
    {
      id: 'commonNameDictWord',
      description: 'Not a name or dictionary word',
      isValid: /^(?:(?![A-Za-z]+(?: [A-Za-z]+)?\d*$)\S+)$/i.test(password)
    }
  ];

  return rules;
};
