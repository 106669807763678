import { AxiosResponse } from 'axios';
import { get } from '../Request/Request';
import IUserList from '../UserList/UserList.interface';
import { orgUserList } from './OrganizationAdmin.helper';
import IOrganizationAdmin from './OrganizationAdmin.interface';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class OrganizationAdmin {
  // Declaring the sub-path
  public subPath: string = '/organization/admin';

  // GET request for fetching data from db to Organization Admin. This method is an array of type IUserList or null
  public async getAllOrganization(): Promise<Array<IUserList | null>> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'GET');
      const url: string = this.createUrl(this.subPath);
      const response: AxiosResponse<any, any> = await axiosWithRoles.get(url);
      const arrAdmin: Array<IOrganizationAdmin | null> = [];
      if (Array.isArray(response.data)) {
        response.data.forEach((element: IOrganizationAdmin) => {
          arrAdmin.push(element);
        });
      }
      return orgUserList(arrAdmin);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        // Display a custom message for 404 errors
      } else {
        // Handle other errors
        throw error;
      }
    }
    // return await this.getAllOrganization();
    return [];
  }

  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
