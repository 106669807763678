import React from 'react';

// utility function for async react callback properties when void expected
export const wrapAsyncFunction = <ARGS extends unknown[]>(
  fn: (...args: ARGS) => Promise<unknown>
): ((...args: ARGS) => void) => {
  return (...args) => {
    void fn(...args);
  };
};
